import Vue from 'vue';
import VueGtm from 'vue-gtm';
import Router from '../router';

export default (gtmId: string | null): void => {
  if (gtmId) {
    Vue.use(VueGtm, {
      id: gtmId,
      debug: process.env.NODE_ENV !== 'production',
      loadScript: true,
      vueRouter: Router,
      ignoredViews: [],
      trackOnNextTick: false
    });
  }
};
