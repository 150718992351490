import Vue from 'vue';
import '@/plugins/ne-vue-components';
import initSentry from '@/plugins/sentry';
import initGTM from '@/plugins/gtm';
import YouTubePlayer from '@/plugins/video-player';
import App from './App.vue';
import router from '@/router';
import { getEnvVariables } from '@/core/env';
import { UrlService } from '@/core/url-service';

const initializeApp = async () => {
  Vue.config.productionTip = false;
  const {
    VUE_APP_SENTRY_DSN,
    VUE_APP_ENVIRONMENT,
    VUE_APP_DLN_API_URL,
    VUE_APP_MNE_URL,
    VUE_APP_GTM_CODE
  } = await getEnvVariables();
  initSentry(VUE_APP_SENTRY_DSN, VUE_APP_ENVIRONMENT);
  initGTM(VUE_APP_GTM_CODE);

  /* eslint-disable no-new */
  new YouTubePlayer();
  new UrlService(VUE_APP_MNE_URL, VUE_APP_DLN_API_URL);

  new Vue({
    name: 'ODN',
    router,
    render: (h: any) => h(App)
  }).$mount('#app');

  Vue.config.devtools = true;
};

initializeApp();
