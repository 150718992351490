import axios from 'axios';

export interface EnvConfig {
  VUE_APP_SENTRY_DSN: string;
  VUE_APP_ENVIRONMENT: string;
  VUE_APP_DLN_API_URL: string;
  VUE_APP_MNE_URL: string;
  VUE_APP_GTM_CODE: string | null;
}

export const getEnvVariables = async () => {
  let envConfig: EnvConfig = {
    VUE_APP_SENTRY_DSN: 'https://324035543b5249eaaad31f2c1344a78c@sentry.nowaera.pl/57',
    VUE_APP_ENVIRONMENT: 'DEV',
    VUE_APP_DLN_API_URL: 'https://api-dlanauczyciela.tst.nowaera.pl',
    VUE_APP_MNE_URL: 'https://moja.nowaera.pl/',
    VUE_APP_GTM_CODE: 'GTM-PN8J3XJ'
  };
  try {
    envConfig = (await axios.get<EnvConfig>('/env.json')).data;
  } catch (e) {
    console.log(e);
  }
  return envConfig;
};
