import Vue from 'vue';
import Router from 'vue-router';
import { basePath } from '@/config';
import NoAccess from '@/views/NoAccess/NoAccess.vue';
import MaterialsToDownload from '@/views/MaterialsToDownload/MaterialsToDownload.vue';
import VideoPlayer from '@/views/VideoPlayer/VideoPlayer.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: basePath,
  routes: [
    {
      path: '/:hash/:videoId',
      component: VideoPlayer
    },
    {
      path: '/:hash',
      component: MaterialsToDownload
    },
    {
      path: '*',
      component: NoAccess
    }
  ]
});
