





























import Vue from 'vue';
import axios from 'axios';
import { IListPackageFiles, IListToDownload } from '@/models/video-player';
import { UrlService } from '@/core/url-service';
import { NeVideoPlayer } from '@ne/ne-vue2-lib';

export default Vue.extend({
  name: 'VideoPlayer',
  components: {
    NeVideoPlayer
  },
  data () {
    return {
      playerVars: {
        autoplay: 0
      },
      list: {} as IListToDownload
    };
  },
  computed: {
    videoObj (): IListPackageFiles {
      const videoId = Number(this.hashVideoId[1]);
      const empty = {
        id: NaN,
        title: '',
        type: '',
        videoLink: null,
        download: {
          state: null
        }
      };
      if (this.list.files) {
        return this.list.files.find(e => e.id === videoId) || empty;
      } else {
        return empty;
      }
    },
    hashVideoId (): string[] {
      const hash: string[] = window.location.pathname.split('/');
      hash.shift();
      return hash; // ['igejffpgbj', '219004']
    },
    idFromUrl (): string | null {
      let videoID: string;
      if (this.videoObj.videoLink) {
        if (this.videoObj.videoLink.length > 11) {
          videoID = this.videoObj.videoLink.replace('https://www.youtube.com/watch?v=', '');
        } else {
          videoID = this.videoObj.videoLink;
        }
        return videoID;
      } return null;
    },
    canDownload (): boolean | null {
      return this.videoObj.download.state;
    },
    videoSrc (): string {
      return `${UrlService.dlnUri}/api/v1/files/${this.videoObj.id}/stream`;
    },
    isLinkYT (): boolean {
      return this.videoObj.type === 'text/link.yt';
    }
  },
  created () {
    this.getList().then((result: IListToDownload) => {
      if (result.days === 0) {
        this.$router.push({ path: '/' });
      }
      this.list = result;
    }).catch(() => {
      this.$router.push({ path: '/' });
    });
  },
  methods: {
    async getList (): Promise<IListToDownload> {
      return (await axios.get<IListToDownload>(`${UrlService.dlnUri}/api/v1/share/${this.hashVideoId[0]}`)).data;
    },
    preventContextMenu (e: Event): void {
      if (!this.canDownload) {
        e.preventDefault();
      }
    }
  }
});
