

















import Vue, { PropOptions } from 'vue';

export default Vue.extend({
  name: 'RegulationModal',
  props: {
    modalText: {
      type: String,
      default: ''
    } as PropOptions<string>
  },
  methods: {
    close (): void {
      this.$emit('close');
    }
  }
});
