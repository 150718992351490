




























































































import Vue from 'vue';
import axios from 'axios';
import { IMaterialsToDownload, IModalData } from '@/models/materials-to-download';
import { NeButton } from '@ne/ne-vue2-lib';
import { fileSize } from '@/shared/helpers/file-data';
import { activeDateFormat } from '@/shared/helpers/active-date-format';
import RegulationModal from '@/shared/components/Modal/RegulationModal.vue';
import { ErrorName, EventName } from '@/models/gtm';
import { UrlService } from '@/core/url-service';

export default Vue.extend({
  name: 'MaterialsToDownload',
  components: {
    NeButton,
    RegulationModal
  },
  data () {
    return {
      materials: {} as IMaterialsToDownload,
      regulationData: {} as IModalData,
      showModal: false,
      isLoaded: false
    };
  },
  computed: {
    filesCount (): string {
      const files = this.materials.files ? this.materials.files : [];
      return fileSize(files.reduce((prevVal, curVal) => prevVal + curVal.size, 0));
    },
    mneURL (): string {
      return `${UrlService.mneUri}/reg-dlanauczyciela-udostepnianie-zasobow`;
    },
    formattedDate (): string {
      const date = this.materials ? this.materials.activeTo : '';
      return activeDateFormat(date);
    },
    modalData (): string {
      return this.regulationData?.data?.attributes?.value || '';
    },
    author (): string {
      if (this.materials.author) {
        const nameArray = this.materials.author.split(' ');
        return nameArray.length > 1 ? `${nameArray[0]} ${nameArray[1].charAt(0)}.` : `${nameArray[0]}`;
      }
      return '';
    }
  },
  created () {
    this.getList().then((result: IMaterialsToDownload) => {
      if (result.days === 0) {
        this.$gtm.trackEvent({
          event: EventName.SHOW_PAGE,
          error: ErrorName.DATE_LIMIT
        });
        this.$router.push({ path: '/' });
      }
      this.$gtm.trackEvent({
        event: EventName.SHOW_PAGE,
        resources: result.files
      });
      this.materials = result;
      this.isLoaded = true;
    }).catch(() => {
      this.$gtm.trackEvent({
        event: EventName.DOWNLOAD_PACK,
        error: ErrorName.NO_ACCESS
      });
      this.$router.push({ path: '/' });
    });
    this.getRegulation().then((result: IModalData) => {
      this.regulationData = result;
    }).catch(() => {
      this.regulationData = {
        data: {
          type: 'error',
          id: 'id_error',
          attributes: {
            value: 'Nie udało się pobrać danych z MNE'
          }
        }
      };
    });
  },
  methods: {
    download (): void {
      this.$gtm.trackEvent({
        event: EventName.DOWNLOAD_PACK,
        files: this.materials.files
      });
      const hash: string = this.materials.hash;
      const a: HTMLAnchorElement = document.createElement('a');
      const url: string = `${UrlService.dlnUri}/api/v1/share/${hash}/download`;
      a.href = url;
      a.download = url.split('/').pop() || '';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    clickFileAction (fileId: number, itemType: string): void {
      if (itemType.indexOf('video/mp4') >= 0 || itemType.indexOf('text/link.yt') >= 0) {
        window.open(`${this.materials.url}/${fileId}`, '_blank');
      } else {
        const a: HTMLAnchorElement = document.createElement('a');
        const hash: string = this.materials.hash;
        const url: string = `${UrlService.dlnUri}/api/v1/share/${hash}/download/${fileId}`;
        a.href = url;
        a.download = url.split('/').pop() || '';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    },
    openModal (): void {
      this.showModal = true;
    },
    closeModal (): void {
      this.showModal = false;
    },
    async getList (): Promise<IMaterialsToDownload> {
      const hash: string = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
      return (await axios.get<IMaterialsToDownload>(`${UrlService.dlnUri}/api/v1/share/${hash}`)).data;
    },
    async getRegulation (): Promise<IModalData> {
      return (await axios.get<IModalData>(`${UrlService.mneUri}/api/public/v1/rodo/dlanauczyciela-udostepnianie-zasobow_regulations`)).data;
    }
  }
});
