import Vue from 'vue';
import NeVueComponents from '@ne/ne-vue2-lib';
import { CustomOptions } from '@ne/ne-vue2-lib/src/types';
import '@ne/ne-icons-font/dist/ne-icons.css';
import '@ne/ne-icons-font/dist/ne-file-icons.css';

const neVueOptions: CustomOptions = {
  locale: 'pl'
};

Vue.use(NeVueComponents, neVueOptions);
