import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { SentryEnv } from '@/config';
import { Integrations } from '@sentry/tracing';

export default (dsn: string, environment: string) => {
  if (dsn) {
    Sentry.init({
      Vue,
      debug: environment === SentryEnv.DEV,
      dsn,
      environment,
      integrations: [new Integrations.BrowserTracing()],
      logErrors: environment !== SentryEnv.PRD && environment !== SentryEnv.PRE,
      tracingOptions: {
        trackComponents: true
      }
    });
  }
};
